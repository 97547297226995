import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ProductValueProps.module.scss'

import ProductValuePropCard from './ProductValuePropCard/ProductValuePropCard'

import productValuePropsConfig from './productValuePropsConfig.jsx'
import useScrollToFaqsAndSpecs from '@/hooks/useScrollToFaqsAndSpecs'

const ProductValueProps = ({ className, heading, productCode }) => {
    const { ScrollToFaqsAndSpecsButton } = useScrollToFaqsAndSpecs()

    const config = productValuePropsConfig[productCode]
    if (!config) {
        return null
    }

    const { heading: productCodeHeading, productValueProps } = config
    const sectionHeading = heading || productCodeHeading
    const classes = classNames('section', styles.productValueProps, className)
    const wrapperClasses = classNames(styles.productValuePropsWrap)

    const valuePropItemClasses = [
        styles.productValuePropsItem0,
        styles.productValuePropsItem1,
        styles.productValuePropsItem2,
        styles.productValuePropsItem3,
    ]

    return (
        <section className={classes}>
            <div className="container">
                {sectionHeading
                    ? <h3 className="t-heading2 u-marginBottom--2dot5xl">
                        What makes our{' '}
                        <span className={classNames(styles.productValuePropsMainTitle, 't-heading2')}>
                            {sectionHeading}
                        </span>{' '}
                        so special?
                    </h3> : <></>}
                <div className={wrapperClasses}>
                    {productValueProps.map((valueProp, i) => (
                        <ProductValuePropCard
                            key={`valueProp-${i}`}
                            {...valueProp}
                            cardClassnames={classNames(styles.productValuePropsItem, valuePropItemClasses[i])}
                            scrollFunction={ScrollToFaqsAndSpecsButton}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

ProductValueProps.propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    productCode: PropTypes.string
}

export default React.memo(ProductValueProps)
