import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ProductValuePropCard.module.scss'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'

const ProductValuePropCard = ({
    title,
    description,
    svgId,
    hasScroll,
    scrollFunction,
    cardClassnames,
    contentClassnames,
    headingClassnames,
    titleClassnames,
    descriptionClassnames,
    list
}) => {
    const cardClasses = classNames(styles.productValuePropCard, cardClassnames)
    const contentClasses = classNames(styles.productValuePropCardContent, contentClassnames)
    const headingClasses = classNames(styles.productValuePropCardContentHeading, headingClassnames)
    const titleClasses = classNames(
        styles.productValuePropCardContentHeadingTitle,
        titleClassnames
    )
    const descriptionClasses = classNames(
        styles.productValuePropCardContentHeadingDescription,
        descriptionClassnames
    )

    const renderList = (list) => {
        return (
            <ul className={styles.productValuePropCardList}>
                {list.map((item, i) => {
                    return (typeof item === 'object'
                        ? <li>{item.description(scrollFunction)}</li>
                        : <li key={`valueProp${i}`} className={`productValuePropCard__listItem`} dangerouslySetInnerHTML={{ __html: item }}></li>
                    )
                })}
            </ul>
        )
    }

    return (
        <div className={cardClasses}>
            <div className={contentClasses}>
                <div className={headingClasses}>
                    <SvgSprite spriteID={svgId}/>
                    <h4 className={titleClasses}>{title}</h4>
                </div>
                <div className={descriptionClasses}>
                    <p>
                        {hasScroll && scrollFunction
                            ? list ? renderList(list) : description(scrollFunction)
                            : description || renderList(list)}
                    </p>
                </div>
            </div>
        </div>
    )
}

ProductValuePropCard.propTypes = {
    title: PropTypes.string.isRequired,
    // Description accepts a function for ctas or links in the description
    description: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
    svgId: PropTypes.string.isRequired,
    hasScroll: PropTypes.bool,
    // Func to handle scrolling to certain parts of the page (like FAQS) from the description
    scrollFunction: PropTypes.func,
    cardClassnames: PropTypes.string,
    contentClassnames: PropTypes.string,
    headingClassnames: PropTypes.string,
    titleClassnames: PropTypes.string,
    descriptionClassnames: PropTypes.string
}

export default ProductValuePropCard
